import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import { emoji, title, lastPhoto, buttonSection } from "./footer-stylize-section.module.scss";
import Kiss from "../../assets/images/svg/kiss.svg";
import { ISite } from "../../models/site.model";
import { commonTranslations } from "../../utils/get-translation-key";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import SectionWrapper from "./section-wrapper";
import Section from "./section";
import Link from "../atoms/link";

interface IFooterMeasureProps {
    data: { site: ISite } & { [key: string]: ImageDataLike };
    className?: string;
}

const FooterStylizeSection: React.FC<IFooterMeasureProps> = ({ data }) => {
    const { t } = useI18next();
    const { logo } = data;
    const aboutUsLastSection = getImage(logo);
    return (
        <SectionWrapper bgColor={"beige-light"}>
            <Section column={"narrow"} columnTablet={"narrow"} columnPhone={"regular"}>
                <div className={title}>
                    Miłej zabawy z modą życzy Emilia z całym zespołem Fashion Fairy{" "}
                    <Kiss className={emoji} />{" "}
                </div>
                <div className={lastPhoto}>
                    {aboutUsLastSection && <GatsbyImage alt={""} image={aboutUsLastSection} />}
                </div>
            </Section>
            <Section column={"narrow"} className={buttonSection}>
                <Link color="primary" type="filled" to={"wystylizuj-mnie"} textUppercase>
                    {t(commonTranslations("stylize"))}
                </Link>
            </Section>
        </SectionWrapper>
    );
};

export default FooterStylizeSection;
